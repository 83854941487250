document.querySelector('.address__copy').addEventListener("click", (e) => {
    e.preventDefault();

    ['name', 'street', 'city', 'zip', 'phone', 'email'].forEach(field => {
        document.getElementById('shipping_' + field).value = document.getElementById('invoice_' + field).value;
    });
});

document.querySelector('.products__new').addEventListener("click", handleProductAdd);

function handleProductAdd(e = null, oldProduct = null) {
    e?.preventDefault();

    const list = document.querySelector(".products__list");
    const template = document.querySelector("#productrow");
    const clone = template.content.cloneNode(true);

    clone.querySelector('select').addEventListener("change", handleProductSelect);
    clone.querySelector('.products__remove').addEventListener("click", handleProductRemove);

    if (oldProduct) {
        clone.querySelector('select').value = oldProduct.name;
        handleProductSelect(null, oldProduct, clone);
    }

    list.appendChild(clone);
}

function handleProductSelect(e = null, oldProduct = null, clone = null) {
    const product = window.products[oldProduct?.name ?? this.value];
    const productRow = clone ?? this.parentElement.parentElement;
    console.log(oldProduct);
    productRow.querySelector('input[name="products[][package]"]').value = product[1];

    const qty = productRow.querySelector('input[name="products[][qty]"]');
    const minValue = product[2] ? product[2] : 1;
    qty.value = oldProduct?.qty ?? minValue;
    qty.min = minValue;
    qty.addEventListener("change", function () {
        let v = parseInt(this.value);
        if (v < minValue) this.value = minValue;
    });

    productRow.querySelector('.form__unit').innerHTML = product[3] && product[1] == "lose" ? product[3] : "";
}

function handleProductRemove(e) {
    e.preventDefault();
    this.parentElement.remove();
}

if (window.oldProducts.length > 0 && window.oldProducts[0].name) {
    window.oldProducts.forEach(product => {
        handleProductAdd(null, product);
    });
} else {
    handleProductAdd();
}
