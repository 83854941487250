import $ from 'jquery';
window.$ = window.jQuery = $;

import './hamburger';
import './gallery';
import './order';

document
    .querySelector(".hamburger")
    .addEventListener("click", () => {
        document.querySelector(".hamburger").classList.toggle("hamburger--open");
        document
            .querySelector(".nav")
            .classList.toggle("nav--active");
    });
